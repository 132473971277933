<template>

<div class="grid">
    <div class="col-12">
      <div id="custom_card" class="card">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
            >
              <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                Add User
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
            >
            <Button style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  " class="p-button-primary" size="small"  label="Back"  v-tooltip.bottom="'back'" @click="Goback"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-12">
        <div class="card card-w-title" style="height: 800px">
            <!-- <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" /> -->
          
          
          
            <tabs
    v-model="selectedTab"
  >

    <tab
      class="tab"
      v-for="(tab, i) in tabs"
      :key="`t${i}`"
      :val="tab"
      :label="tab"
      :indicator="true"
    />
  </tabs>
    <tab-panels
    v-model="selectedTab"
    :animate="true"
  >
    <tab-panel >
      
      <BulkUploadUser  v-if="selectedTab=='Bulk Upload'"></BulkUploadUser>
      <div class="grid" v-if="selectedTab=='Add User'">
        <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
        <div class="col-12">
            <div class="card">
                
                <div class="p-fluid formgrid grid">

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown id="status" v-model="status_dropdownItem" :options="status_dropdownItems" 
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !status_dropdownItem }"></Dropdown>
                        <!-- <InputText id="status" type="text" v-model="product.status" :class="{ 'p-invalid': submitted && !product.status }"/> -->
                        <label for="status">Status</label>
                        </span>
                    </div>
                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown id="user_type" v-model="user_type_dropdownItem" :options="user_type_dropdownItems"
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !user_type_dropdownItem }"></Dropdown>
                       
                        <label for="user_type">User Type</label>
                        </span>

                    </div> -->
                    <div v-if="user_type=='Admin'" class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown id="client" v-model="client_dropdownItem" :options="client_dropdownItems" @change="get_user_type(client_dropdownItem.value)"
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !client_dropdownItem }"></Dropdown>
                     
                        <label for="client">Clients</label>
                        </span>

                    </div>
                    <div v-else class="field col-12 md:col-6" style="margin-top: 1%;"></div>

                    

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="firstname2" type="text" v-model="product.first_name"
                                :class="{ 'p-invalid': submitted && !product.first_name }" />
                            <label for="firstname2">Firstname</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="lastname2" type="text" v-model="product.last_name"
                                :class="{ 'p-invalid': submitted && !product.last_name }" />
                            <label for="lastname2">Lastname</label>
                        </span>
                    </div>


                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="password" type="password" v-model="product.password"
                                :class="{ 'p-invalid': submitted && !product.password }" />
                            <label for="password">Password</label>
                        </span>
                    </div>

                    <!-- <div v-if="user_type=='Admin'" class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown id="client" v-model="client_dropdownItem" :options="client_dropdownItems"
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !client_dropdownItem }"></Dropdown>
                     
                        <label for="client">Clients</label>
                        </span>

                    </div>
                    <div v-else class="field col-12 md:col-6" style="margin-top: 1%;"></div> -->
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown id="user_type" v-model="user_type_dropdownItem" :options="user_type_dropdownItems"
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !user_type_dropdownItem }"></Dropdown>
                       
                        <label for="user_type">User Type123</label>
                        </span>

                    </div>
                   


                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="email" type="text" v-model="product.email_id"
                                :class="{ 'p-invalid': submitted && !product.email_id }" />
                            <label for="email">Email</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText type="text" id="mobile_number" v-model="product.mobile_number"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                maxlength="10"
                                :class="{ 'p-invalid': submitted && !product.mobile_number }" />
                            <label for="mobile_number">Mobile Number</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="alternate_mobile_number" type="text" v-model="product.alternate_contact_no"                                
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                maxlength="10"
                                :class="{ 'p-invalid': submitted && !product.alternate_contact_no }" />
                            <label for="alternate_mobile_number">Alternate Mobile Number</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                       <Dropdown id="gender" v-model="gender_dropdownItem" :options="gender_dropdownItems"
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !gender_dropdownItem }"></Dropdown>
                            <label for="gender">Gender</label>
                            </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Calendar
                       
                        v-model="product.dob"
                        dateFormat="dd-mm-yy"
                       
                        :showTime="false"
                        hourFormat="12"
                        />
                        <label for="dob">Birth Date</label>
                    </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                       <Dropdown id="department" v-model="department" :options="departments"
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !department }"></Dropdown>
                            <label for="department">Department</label>
                            </span>
                    </div>


                    <div class="field col-12" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <Textarea id="address" rows="4" v-model="product.address"
                                :class="{ 'p-invalid': submitted && !product.address }" />
                            <label for="address">Address</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-12" style="margin-top: 1%;">
                        <!-- <span class="p-float-label"> -->
                            <label for="Location">Location</label>
                        <Dropdown 
                                    @change="get_location"
                                    v-model="location_search" 
                                    :options="location_dropdown" 
                                    optionLabel="name" 
                                    filter 
                                    @filter="dropdown_list"
                                    placeholder="Location" 
                                    >
                            <template #value="slotProps">
                            <div class="country-item country-item-value" v-if="slotProps.value">
                                <div>{{slotProps.value.name}}</div>
                            </div>
                                <span v-else>
                                {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                            <span>{{slotProps.option.name}}</span>
                            </template>
                            
                        </Dropdown>
                        
                    <!-- </span> -->
                    </div>



                    <div class="field col-12 md:col-4" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown id="state" v-model="state_dropdownItem" :options="state_dropdownItems" optionLabel="name" filter
                             :class="{ 'p-invalid': submitted && !state_dropdownItem }">
                        </Dropdown>
                        <!-- <InputText id="state" type="text" v-model="product.state" :class="{ 'p-invalid': submitted && !product.state }"/> -->
                        <label for="state">State</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-4" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <!-- <Dropdown id="state" v-model="dropdownItem" filter :options="dropdownItems" optionLabel="name" placeholder="Select One"></Dropdown>
                         -->
                            <InputText id="state" type="text" v-model="product.city"
                                :class="{ 'p-invalid': submitted && !product.city }" />
                            <label for="state">City</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-4" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="zip" type="text" v-model="product.pinvalue"
                                :class="{ 'p-invalid': submitted && !product.pinvalue }" />
                            <label for="zip">Pincode</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-4" style="margin-top: 1%;"></div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <Button v-if="product._id" label="Update" class="p-button-success mr-2 btn_light_blue" @click="Add_user" />
                        <Button v-else label="Add" class="p-button-success mr-2 btn_light_blue" @click="Add_user" />
                    </div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <Button label="Reset" class="p-button-danger mr-2 btn_red" @click="reset_user" />
                    </div>

                </div>
            </div>
        </div>
    </div>
    </tab-panel>
  </tab-panels>

      </div>  
    </div>

</div>




    
</template>

<script>
import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';
import BulkUploadUser from '../components/menu/BulkUploadUser'
import { FilterMatchMode } from "primevue/api";
import InputValidation from '../service/InputValidation';
import ProductService from "../service/ProductService";
import BreadcrumbService from "../service/BreadcrumbService";
import JsonExcel from "vue-json-excel";
import apis from "@/apis";
export default {
    data() {
        return {
            
            active:"active",
            tabs : ['Add User','Bulk Upload'],
            selectedTab: "Add User",


            departments:[],
            department:null,
            location_dropdown: [],
            location_search:'',
            place_details:'',
            client_id:'',
            user_type:'',
            id: "",
            list: [],
           
            status_dropdownItems: [
                { name: "Active", value: "Active" },
                { name: "InActive", value: "InActive" },
                { name: "Deleted", value: "Deleted" },
            ],
            status_dropdownItem: null,

            gender_dropdownItems: [
                { name: 'Male', value: 'Male' },
                { name: 'Female', value: 'Female' },
                { name: 'Other', value: 'Other' }
            ],
            gender_dropdownItem: null,


            state_dropdownItems: [{ name: 'Andhra Pradesh', value: 'Andhra Pradesh' }, { name: 'Arunachal Pradesh', value: 'Arunachal Pradesh' }, { name: 'Assam', value: 'Assam' }, { name: 'Bihar', value: 'Bihar' }, { name: 'Chhattisgarh', value: 'Chhattisgarh' }, { name: 'Goa', value: 'Goa' }, { name: 'Gujarat', value: 'Gujarat' }, { name: 'Haryana', value: 'Haryana' }, { name: 'Himachal Pradesh', value: 'Himachal Pradesh' }, { name: 'Jharkhand', value: 'Jharkhand' }, { name: 'Karnataka', value: 'Karnataka' }, { name: 'Kerala', value: 'Kerala' }, { name: 'Madhya Pradesh', value: 'Madhya Pradesh' }, { name: 'Maharashtra', value: 'Maharashtra' }, { name: 'Manipur', value: 'Manipur' }, { name: 'Meghalaya', value: 'Meghalaya' }, { name: 'Mizoram', value: 'Mizoram' }, { name: 'Nagaland', value: 'Nagaland' }, { name: 'Odisha', value: 'Odisha' }, { name: 'Punjab', value: 'Punjab' }, { name: 'Rajasthan', value: 'Rajasthan' }, { name: 'Sikkim', value: 'Sikkim' }, { name: 'Tamil Nadu', value: 'Tamil Nadu' }, { name: 'Telangana', value: 'Telangana' }, { name: 'Tripura', value: 'Tripura' }, { name: 'Uttar Pradesh', value: 'Uttar Pradesh' }, { name: 'Uttarakhand', value: 'Uttarakhand' }, { name: 'West Bengal', value: 'West Bengal' }],
            state_dropdownItem: { name: "", value: "" },

            user_type_dropdownItems: [],
            user_type_dropdownItem: null,

            client_dropdownItems: [],
            client_dropdownItem: null,

            show_name:"Add",

            product: {},
            submitted: false,

            BreadcrumbService : null,
            breadcrumbHome: {},
            breadcrumbItems: [],
        }

    },
    productService: null,
    methods: {
        async Goback()
        {
            this.$router.push({ name: "usermaster" });
        },
        get_location()
      { 
        this.place_details='';
        var data = { PlaceId:this.location_search.value};
        var promise = apis.geocodelocationdetails(data);
        promise.then((response) => {
          if (response.data.result) 
          {
            this.place_details=response.data.result[0];
            this.state_dropdownItem.name=response.data.result[0].Region?response.data.result[0].Region:'';
            this.state_dropdownItem.value=response.data.result[0].Region?response.data.result[0].Region:'';
            this.product.pinvalue=response.data.result[0].PostalCode?response.data.result[0].PostalCode:'';
            this.product.city=response.data.result[0].Municipality?response.data.result[0].Municipality:'';
          }
        });
      },
       dropdown_list(search)
      { 
       var data = { search:search.value};
        var promise = apis.geocodelats(data);
        promise.then((response) => {
          this.location_dropdown=[];
          var temp=response.data.result;
          for (let a = 0; a < temp.length; a++) {
            this.location_dropdown.push( { name: temp[a].Text, value: temp[a].PlaceId });
            
          }
        });
      },
        Add_user() {
            this.submitted = true;
            this.product.location=this.place_details;
           
            if (this.status_dropdownItem==null || this.status_dropdownItem.value=='' || this.status_dropdownItem.value==undefined ||  this.status_dropdownItem.value==[]) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Select Status",
                    life: 3000,
                });
                return false;
            }
            if (this.user_type_dropdownItem==null || this.user_type_dropdownItem.value=='' || this.user_type_dropdownItem.value==undefined ||  this.user_type_dropdownItem.value==[]) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Select User Type",
                    life: 3000,
                });
                return false;
            }
            // if (this.client_dropdownItem==null || this.client_dropdownItem.value=='' || this.client_dropdownItem.value==undefined ||  this.client_dropdownItem.value==[]) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Select Client",
            //         life: 3000,
            //     });
            //     return false;
            // }

            if (!this.product.first_name) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter First Name",
                    life: 3000,
                });
                return false;
            }

            if (!this.product.last_name) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Last Name",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.password) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Password",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.email_id) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Email ID",
                    life: 3000,
                });
                return false;
            }
            var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            if(this.product.email_id)
            {
                if(emailreg.test(this.product.email_id)==false)
                {
                    this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter valid Email ID",
                    life: 3000,
                });
                  return false;
                }
            }
            const phoneRegex = /^[0-9]{10}$/;
            if (!this.product.mobile_number || (this.product.mobile_number && !phoneRegex.test(this.product.mobile_number))) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter valid Mobile No",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.alternate_contact_no || (this.product.alternate_contact_no && !phoneRegex.test(this.product.alternate_contact_no))) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Alternete Mobile No.",
                    life: 3000,
                });
                return false;
            }
            if (this.gender_dropdownItem==null || this.gender_dropdownItem.value=='' || this.gender_dropdownItem.value==undefined ||  this.gender_dropdownItem.value==[]) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Select Gender",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.dob) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Date of Birth",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.address) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Address",
                    life: 3000,
                });
                return false;
            }
            // if (!this.product.location) 
            // {
            //     this.$toast.add({
            //         severity: "error",
            //         summary: "error",
            //         detail: "Please Enter Location",
            //         life: 3000,
            //     });
            //     return false;
            // }
            if (this.state_dropdownItem==null || this.state_dropdownItem.value=='' || this.state_dropdownItem.value==undefined ||  this.state_dropdownItem.value==[]) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Select State",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.city) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter city",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.pinvalue) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Pin code",
                    life: 3000,
                });
                return false;
            }
            
            



        //ui added for user_edit
        // if (this.product._id) 
        //     { //edit
        //         var data1 = {
        //         "_id":this.product._id,
        //         "first_name": this.product.first_name,
        //         "last_name": this.product.last_name,
        //         "email_id": this.product.email_id,
        //         "mobile_number": this.product.mobile_number,
        //         "gender": this.gender_dropdownItem.value,
        //         "password": this.product.password,
        //         "alternate_contact_no": this.product.alternate_contact_no,
        //         "user_type": this.user_type_dropdownItem.value,
        //         "client_id": this.client_dropdownItem.value? this.client_dropdownItem.value:localStorage.getItem('client_id'),
        //         "status": this.status_dropdownItem.value,
        //         "state": this.state_dropdownItem.value,
        //         "updated_at": "",
        //         "updated_by": this.id,
        //         "city": this.product.city,
        //         "pincode": this.product.pinvalue,
        //         "dob":this.dateFormatYMD(this.product.dob),
        //         "location":this.product.location,
        //         "address":this.product.address
               
        //     };

        
        //     this.isLoadingModel = true;
        //     var promise = apis.edituser(data1);
        //     promise
        //         .then((responseapi) => {
        //             this.$swal(responseapi.data.message);
        //             this.isLoadingModel = false;
        //             this.reset_user();
        //             this.$router.push({name:"usermaster"});

        //         })
        //         .catch((error) => {
        //             //console.log(error);
        //             this.isLoadingModel = false;
        //             this.$swal.fire(error.response.data);

        //         });
                
        //     }
            
            //else
            //{

            
            
            var data = {
                
                "first_name": this.product.first_name,
                "last_name": this.product.last_name,
                "email_id": this.product.email_id,
                "mobile_number": this.product.mobile_number,
                "gender": this.gender_dropdownItem.value,
                "password": this.product.password,
                "alternate_contact_no": this.product.alternate_contact_no,
                "user_type": this.user_type_dropdownItem.value,
                "client_id": localStorage.getItem('client_id')? localStorage.getItem('client_id'):this.client_dropdownItem.value,
                "status": this.status_dropdownItem.value,
                "state": this.state_dropdownItem.value,
                "created_at": "",
                "created_by": this.id,
                "city": this.product.city,
                "pincode": this.product.pinvalue,
                "dob":this.dateFormatYMD(this.product.dob),
                "location":this.product.location,
                "address":this.product.address
               
            };

            console.log(data);
            this.isLoadingModel = true;
            var promise1 = apis.regUser(data);
            promise1
                .then((responseapi) => {
                    this.$swal(responseapi.data.message);
                    this.isLoadingModel = false;
                    this.reset_user();
                    this.$router.push({name:"usermaster"});

                })
                .catch((error) => {
                    //console.log(error);
                    this.isLoadingModel = false;
                    this.$swal.fire(error.response.data);

                });

           // }



        },
        getdepartment_list: function () {
      
      var data = { client_id:localStorage.getItem("client_id")};
      this.loading = true;
      var promise = apis.clientdepartmentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.departments = response.data.data.map(depart => ({ name: depart.name, value: depart._id }));
        
      });

      console.log(data);
    },
        
        get_user_type(client_id) {
            
            
            this.loading = true;
            var data = { 
               // _id: this.id
                _id: client_id
            };
            var promise = apis.user_rolelist(data);
            promise.then((response) => {
                this.loading = false;
                console.log(response);
                this.list = response.data.data;
                if (response.data.data) {
                    var temp_data = [];
                    for (let x = 0; x < response.data.data.length; x++) {
                        temp_data.push({ name: response.data.data[x].user_role_name, value: response.data.data[x].user_role_name });

                    }
                    this.user_type_dropdownItems = temp_data;
                }

            });
        },
        get_clientist() {
            this.loading = true;
            var data = { 
                //client_role_group: localStorage.getItem('user_key') 
            };
            var promise = apis.getclientist(data);
            promise.then((response) => {
                this.loading = false;
                console.log(response);
                this.list = response.data.data;
                if (response.data.data) {
                    var temp_data = [];
                    for (let x = 0; x < response.data.data.length; x++) {
                        if (response.data.data[x].client_name) {
                            temp_data.push({ name: response.data.data[x].client_name, value: response.data.data[x]._id });
                        }
                        

                    }
                    this.client_dropdownItems = temp_data;
                }
                //---------------------------------------------------------------
                    
                    
                //edit
                if (this.client_dropdownItems.length>0 && this.product.client_id) 
                {   
                    for (let z = 0; z < this.client_dropdownItems.length; z++) 
                    {   
                        if (this.client_dropdownItems[z].value==this.product.client_id) 
                        {   this.client_dropdownItem={};
                            this.client_dropdownItem.name = this.client_dropdownItems[z].name;
                            this.client_dropdownItem.value =  this.product.client_id;
                        }
                    }
                    
                }
               

            });
        },
        reset_user() {
            this.product = {};
            this.submitted = false;
            this.status_dropdownItem = null;
            this.gender_dropdownItem = null;
            this.state_dropdownItem = null;
            this.user_type_dropdownItem = null;
            this.client_dropdownItem = null;
            this.user_dropdownItem = null;
            this.department=null;

            this.show_name="Add";

        },
        dateFormatYMD(inputDate) {
        var postdate=inputDate;
        if(inputDate)
        {
            inputDate=new Date(inputDate);
            if(inputDate.toString()=='NaN-NaN-NaN')
            {
                return postdate;
                
            }
            else
            {
                let date = ("0" + inputDate.getDate()).slice(-2);

        // current month
                let month = ("0" + (inputDate.getMonth() + 1)).slice(-2);

                // current year
                let year = inputDate.getFullYear();
                if(date.toString()=='aN')
                {
                  return postdate;
                }
                else
                {
                  return year+"-"+month+"-"+date;
                }
            }
        }
        else {
           return "";
        }
    },
    },
    async created() {
        this.productService = new ProductService();
        this.InputValidation = new InputValidation();

        this.BreadcrumbService = new BreadcrumbService();
        let Breadcrum_data = this.BreadcrumbService.user_add()
        this.breadcrumbHome=Breadcrum_data.home;
        this.breadcrumbItems=Breadcrum_data.items;


        // console.log(this.InputValidation.emailreg("a@gmail.com"));     if false show error!!!!
	
        this.id = localStorage.getItem('id');
        this.client_id=localStorage.getItem('client_id');
        this.user_type=localStorage.getItem('user_key');
        //alert(this.user_type)
       if (localStorage.getItem('client_id')) 
       {
        await this.get_user_type(localStorage.getItem('client_id'));
       }
      
       await this.get_clientist();
       await this.getdepartment_list();
        if(localStorage.getItem('saveuserdata'))
      {
        this.product=await JSON.parse(localStorage.getItem('saveuserdata'));
     
        this.status_dropdownItem={};
        this.status_dropdownItem.value = await this.product.status;
        this.status_dropdownItem.name = await this.product.status;

        this.user_type_dropdownItem={};
        this.user_type_dropdownItem.value = await this.product.user_type;
        this.user_type_dropdownItem.name = await this.product.user_type;

        this.gender_dropdownItem={};
        this.gender_dropdownItem.value = await this.product.gender;
        this.gender_dropdownItem.name = await this.product.gender;

        this.state_dropdownItem={};
        this.state_dropdownItem.value = await this.product.state;
        this.state_dropdownItem.name = await this.product.state;
        this.product.pinvalue = await this.product.pincode;
        this.product.city = await this.product.city;

        if(this.product.location)
        {
            this.location_search={};
            this.location_search={ "name": this.product.location?this.product.location.Label:'', "value":this.product.location?this.product.location:''};
        }
        
        this.place_details=this.product.location?this.product.location:'';

        

        this.show_name="Update";
      }
      await localStorage.setItem('saveuserdata','');
      

    },

    components: { downloadExcel: JsonExcel,Tabs,
    Tab,
    TabPanels,
    TabPanel,
    BulkUploadUser },
}
</script>
